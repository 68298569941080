import React from "react";
import "./art.css";
import image1 from "./assests/image1.png";
import image2 from "./assests/image2.png";
import image3 from "./assests/image3.png";
import image4 from "./assests/image4.png";
import image5 from "./assests/image5.png";
import image6 from "./assests/image6.png";
import image7 from "./assests/image7.png";
import image8 from "./assests/image8.png";
import image9 from "./assests/image9.png";
import image10 from "./assests/image10.png";
import image11 from "./assests/image11.png";
import image12 from "./assests/image12.png";
import image13 from "./assests/image13.png";
import image14 from "./assests/image14.png";
import image15 from "./assests/image15.png";
import image16 from "./assests/image16.png";




const Art = () => {
    return (
        <div className="art-container">
            <div className="row1">
                <div className="Art-1">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image1} alt="image1" />
                    </div>
                </div>
                <div className="Art-2">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image2} alt="image2" />
                    </div>
                </div>
                <div className="Art-3">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image3} alt="image3" />
                    </div>
                </div>
                <div className="Art-4">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image4} alt="image4" />
                    </div>
                </div>
            </div>
            <div className="row2">
                <div className="Art-5">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image5} alt="image5" />
                    </div>
                </div>
                <div className="Art-6">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image6} alt="image6" />
                    </div>
                </div>
                <div className="Art-7">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image7} alt="image7" />
                    </div>
                </div>
                <div className="Art-8">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image8} alt="image8" />
                    </div>
                </div>
            </div>
            <div className="row3">
                <div className="Art-9">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image9} alt="image9" />
                    </div>
                </div>
                <div className="Art-10">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image10} alt="image10" />
                    </div>
                </div>
                <div className="Art-11">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image11} alt="image11" />
                    </div>
                </div>
                <div className="Art-12">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image12} alt="image12" />
                    </div>
                </div>
            </div>
            <div className="row4">
                <div className="Art-13">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image13} alt="image13" />
                    </div>
                </div>
                <div className="Art-14">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image14} alt="image14" />
                    </div>
                </div>
                <div className="Art-15">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image15} alt="image15" />
                    </div>
                </div>
                <div className="Art-16">
                    <div className="Art-info">
                        <div className="Interaction-Button">
                            <div className="Images">
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M21.1474 5.13178C20.645 4.62921 20.0486 4.23054 19.3922 3.95854C18.7357 3.68654 18.0321 3.54654 17.3215 3.54654C16.611 3.54654 15.9074 3.68654 15.2509 3.95854C14.5945 4.23054 13.998 4.62921 13.4957 5.13178L12.4532 6.17429L11.4107 5.13178C10.396 4.1171 9.01982 3.54707 7.58486 3.54707C6.14989 3.54707 4.7737 4.1171 3.75903 5.13178C2.74435 6.14645 2.17432 7.52264 2.17432 8.9576C2.17432 10.3926 2.74435 11.7688 3.75903 12.7834L4.80154 13.8259L12.4532 21.4776L20.1049 13.8259L21.1474 12.7834C21.6499 12.2811 22.0486 11.6847 22.3206 11.0282C22.5926 10.3718 22.7326 9.66817 22.7326 8.9576C22.7326 8.24703 22.5926 7.54343 22.3206 6.88698C22.0486 6.23053 21.6499 5.63411 21.1474 5.13178Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M18.7439 21.2514L11.8594 16.3339L4.97485 21.2514V5.51535C4.97485 4.99366 5.18209 4.49335 5.55098 4.12446C5.91986 3.75558 6.42018 3.54834 6.94186 3.54834H16.7769C17.2986 3.54834 17.7989 3.75558 18.1678 4.12446C18.5367 4.49335 18.7439 4.99366 18.7439 5.51535V21.2514Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_136_22)">
                                            <path d="M1.44702 12.3999C1.44702 12.3999 5.38104 4.53186 12.2656 4.53186C19.1501 4.53186 23.0841 12.3999 23.0841 12.3999C23.0841 12.3999 19.1501 20.2679 12.2656 20.2679C5.38104 20.2679 1.44702 12.3999 1.44702 12.3999Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.2656 15.3504C13.8951 15.3504 15.2161 14.0294 15.2161 12.3999C15.2161 10.7704 13.8951 9.44937 12.2656 9.44937C10.6361 9.44937 9.31506 10.7704 9.31506 12.3999C9.31506 14.0294 10.6361 15.3504 12.2656 15.3504Z" stroke="#2B2B2B" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_136_22">
                                                <rect width="23.6041" height="23.6041" fill="white" transform="translate(0.463501 0.597839)" />
                                            </clipPath>
                                        </defs>
                                    </svg></li>
                                </ul>
                            </div>
                            <div className="Stats">
                                <ul>
                                    <li>5K</li>
                                    <li>1K</li>
                                    <li>40K</li>
                                </ul>
                            </div>
                        </div>
                        <div className="Art-Name">
                            <h1>Collection Name</h1>
                            <p>Artist </p>
                        </div>
                    </div>
                    <div className="Art-image">
                        <img src={image16} alt="image16" />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Art;