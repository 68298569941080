import React from "react";
import "./line.css";

const Line = () => {
    return (
        <div className="line">
        </div>
    );
}

export default Line;