import React from "react";
import ReactPlayer from "react-player";

function Course2() {
  return (
    <div style={{ width: "100%", height: "auto", marginLeft: "600px" }}>
      <ReactPlayer url="https://www.youtube.com/watch?v=OdSgDir6XKs&feature=youtu.be" />
    </div>
  );
}

export default Course2;
