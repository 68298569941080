import React from "react";
import ReactPlayer from "react-player";

function Course1() {
  return (
    <div style={{ width: "100%", height: "auto", marginLeft: "600px" }}>
      <ReactPlayer url="https://www.youtube.com/watch?v=JGLfyTDgfDc&feature=youtu.be" />
    </div>
  );
}

export default Course1;
