import React from "react";
import ReactPlayer from "react-player";

function Course3() {
  return (
    <div style={{ width: "100%", height: "auto", marginLeft: "600px" }}>
      <ReactPlayer url="https://www.youtube.com/watch?v=AvgCkHrcj90&ab_channel=FlowStudio" />
    </div>
  );
}

export default Course3;
